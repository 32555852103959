import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout title={siteTitle}>
        <SEO
          title="🎛️ Intelligent house and techno music, mixed by AI."
          keywords={[
            `dub ninja`,
            `dub techno`,
            `deep house`,
            `radio`,
            `mix`,
            `ai`,
          ]}
        />
        {/* <Bio /> */}
        {data.site.siteMetadata.description && (
          <header className="page-head">
            <p></p>
            <p></p>
            <iframe src="https://play.dub.ninja/public/dub_ninja/embed"></iframe>
            <p></p>
            <p></p>
            <p></p>
            <h2 className="page-head-title">
              {data.site.siteMetadata.description}
            </h2>
          </header>
        )}
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
